import { useState } from 'react';
import clsx from 'clsx';

import Button from 'components/UI/Button';
import SubscriptionsMethods from 'api/subscriptions/SubscriptionsMethods';
import { useFetchMyProfile } from 'hooks/query/useFetchMyProfile';
import { paymentList } from 'constants/subscribe';
import avatar from '../../assets/images/avatar.png';

import styles from './SubscribePage.module.scss';

export default function SubscribePage() {
  const [activeRate, setActiveRate] = useState(1);

  const { user } = useFetchMyProfile();

  const changeValueRate = (id: number) => {
    setActiveRate(id);
  };

  const handleSubscribe = async () => {
    const currentSubscription = paymentList.find(
      (item) => item.id === activeRate,
    );
    if (currentSubscription && user) {
      return await SubscriptionsMethods.createSubscription(
        user?.id,
        currentSubscription?.plan,
        Number(currentSubscription?.price),
      );
    }
  };

  return (
    <section className={styles.subscribe}>
      <div className={clsx('container', styles.content)}>
        <div className={styles.subscribe__imageWrapper}>
          <img src={avatar} alt="avatar" />
        </div>
        <h2 className={styles.subscribe__name}>Jensen Ackles</h2>
        <div className={styles.subscribe__description}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
        {true ? (
          <>
            {' '}
            <div className={styles.subscribe__rates}>
              {paymentList.map((elem) => (
                <Button
                  variants={activeRate === elem.id ? 'filled' : 'transparent'}
                  key={elem.id}
                  className={clsx(styles.rate, {
                    [styles.border]: activeRate !== elem.id,
                  })}
                  onClick={() => changeValueRate(elem.id)}
                >
                  <span className={styles.rate__text}>
                    {elem.price}₽ / {elem.period}
                  </span>
                </Button>
              ))}
            </div>
            <Button
              variants="filled"
              onClick={handleSubscribe}
              className={styles.subscribe__button}
            >
              Start chatting
            </Button>
          </>
        ) : (
          <Button
            variants="filled"
            onClick={handleSubscribe}
            className={styles.subscribe__button}
          >
            Send a message
          </Button>
        )}
      </div>
    </section>
  );
}
