import { useState } from 'react';

import { useAppDispatch } from 'hooks/store';
import { RECEIVEONLYLIST } from 'constants/setting';
import SettingItem from '../SettingItem';

export type ReceiveOnlyValueType = 'text' | 'audio' | 'any';

export default function ReceiveOnly() {
  const [receiveOnlyValue, setReceiveOnlyValue] =
    useState<ReceiveOnlyValueType>('text');

  const handleChangeReceiveOnly = (value: ReceiveOnlyValueType) => {
    setReceiveOnlyValue(value);
  };

  return (
    <SettingItem
      list={RECEIVEONLYLIST}
      title="Receive only:"
      checkedValue={receiveOnlyValue}
      handleReceiveOnly={handleChangeReceiveOnly}
    />
  );
}
