import Button from 'components/UI/Button';
import useCopy from 'hooks/useCopy';

import styles from './Referral.module.scss';

const text =
  'https://www.google.com/EgZjaHJvbWUqCQgAECMYJxjqAjIJCAAQIxgnGOoCMgkIAR';

export default function Referral() {
  const { handleCopy, isCopied } = useCopy(text);

  return (
    <div className={styles.referral}>
      <div className={styles.referral__header}>
        <h2 className={styles.title}>Referral program</h2>
        <div className={styles.referral__link}>
          <p className={styles.referral__text}>{text}</p>
          <Button
            variants="filled"
            className={styles.referral__button}
            onClick={handleCopy}
          >
            {isCopied ? 'Copied' : 'Copy'}
          </Button>
        </div>
      </div>
      <div className={styles.referral__invited}>
        <h3 className={styles.title}>Invited users</h3>
        <div className={styles.cards}>
          {[...Array.from({ length: 5 })].map((_, index) => (
            <div key={index} className={styles.card}>
              <div className={styles.card__name}>smiledie-hub@yandex.ru</div>
              <span className={styles.card__bonus}>100₽</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
