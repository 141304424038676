import { SVGProps } from 'react';

export default function AddIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24.000000"
      height="24.000000"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="clip17_2444">
          <rect
            id="State=Add"
            width="24.000000"
            height="23.999998"
            fill="currentColor"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip17_2444)">
        <path
          id="path (Border)"
          d="M13.5 6C13.5 5.16 12.84 4.5 12 4.5C11.16 4.5 10.5 5.16 10.5 6L10.5 10.5L6 10.5C5.15 10.5 4.5 11.16 4.5 12C4.5 12.83 5.15 13.5 6 13.5L10.5 13.5L10.5 18C10.5 18.83 11.16 19.5 12 19.5C12.84 19.5 13.5 18.83 13.5 18L13.5 13.5L18 13.5C18.84 13.5 19.5 12.83 19.5 12C19.5 11.16 18.84 10.5 18 10.5L13.5 10.5L13.5 6Z"
          fill="currentColor"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
