import { SVGProps } from 'react';

export default function LogoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40.000000"
      height="40.000000"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="clip31_4796">
          <rect
            id="-dialog-box-with-a-lock-icon-inside"
            width="40.000000"
            height="40.000000"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
        <linearGradient
          x1="32.515152"
          y1="23.853626"
          x2="6.264559"
          y2="18.523598"
          id="paint_linear_31_4786_0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E42D2" />
          <stop offset="1.000000" stopColor="#E2B9F9" />
        </linearGradient>
        <linearGradient
          x1="24.708527"
          y1="12.154183"
          x2="26.019257"
          y2="7.857190"
          id="paint_linear_31_4787_0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2E2F5" />
          <stop offset="1.000000" stopColor="#FFFFFF" />
        </linearGradient>
      </defs>
      <g clipPath="url(#clip31_4796)">
        <path
          id="path"
          d="M11.03 9.12L16.07 9.12L17.7 9.12C17.98 9.12 18.29 9.1 18.57 9.13C19.47 9.11 20.37 9.13 21.26 9.13L26.77 9.13C27.56 9.13 28.34 9.15 29.13 9.13C30.12 9.14 30.87 9.07 31.63 9.84C31.8 10.01 31.88 10.22 32.03 10.39C32.33 11 32.3 11.55 32.28 12.21C32.24 13.32 32.26 14.43 32.26 15.54L32.28 21.87L32.28 23.85C32.28 24.31 32.33 24.81 32.24 25.26C32.14 25.75 31.84 26.23 31.48 26.57C31.43 26.61 31.39 26.65 31.34 26.68C31.3 26.72 31.25 26.76 31.2 26.79C31.15 26.83 31.1 26.86 31.05 26.89C31 26.92 30.95 26.95 30.9 26.98C30.85 27 30.79 27.03 30.74 27.05C30.68 27.07 30.63 27.1 30.57 27.12C30.52 27.14 30.46 27.15 30.4 27.17C30.35 27.18 30.29 27.2 30.23 27.21C29.83 27.29 29.37 27.25 28.96 27.25L26.99 27.25L23.37 27.25C22.74 27.25 22.09 27.27 21.47 27.25C21.44 27.25 21.42 27.25 21.39 27.24C20.86 27.26 20.33 27.25 19.8 27.24L17.47 27.24C15.91 28.78 14.37 30.35 12.84 31.91C12.64 32.11 12.19 32.65 11.98 32.77C11.94 32.79 11.89 32.8 11.85 32.8C11.75 32.8 11.66 32.78 11.59 32.71C11.35 32.49 11.42 31.6 11.42 31.28C11.42 30.06 11.44 28.84 11.42 27.63L11.42 27.28C10.71 27.28 9.93 27.37 9.25 27.12C8.94 26.93 8.75 26.88 8.46 26.6C8.12 26.27 7.91 25.81 7.84 25.34C7.77 24.93 7.8 24.5 7.8 24.08L7.81 21.44L7.79 14.76C7.79 13.97 7.8 13.18 7.79 12.39C7.81 11.41 7.63 10.56 8.4 9.79C9.18 9 10.01 9.12 11.03 9.12Z"
          fill="url(#paint_linear_31_4786_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M18.57 9.13C19.47 9.11 20.37 9.13 21.26 9.13L26.77 9.13C27.56 9.13 28.34 9.15 29.13 9.13C30.12 9.13 30.87 9.06 31.63 9.84C31.8 10.01 31.88 10.22 32.03 10.39C32.33 11 32.3 11.55 32.28 12.21C32.04 12.42 31.78 12.61 31.53 12.81C31.34 12.97 31.16 13.14 30.95 13.28C30.95 12.79 30.95 12.29 30.96 11.8C30.96 11.56 30.98 11.3 30.95 11.07C30.93 10.91 30.88 10.77 30.8 10.64C30.64 10.39 30.41 10.28 30.13 10.22C29.94 10.17 29.48 10.21 29.27 10.21L27.23 10.21L19.9 10.21L17.38 10.21C16.93 10.21 16.49 10.22 16.04 10.2L16.05 10.2L16.03 10.19C16.25 10.2 18.15 10.21 18.21 10.19C18.23 10.18 18.24 10.17 18.25 10.16C18.25 10.11 18.25 10.08 18.27 10.03C18.34 9.99 18.4 9.92 18.43 9.84C18.43 9.72 18.46 9.72 18.41 9.61C18.37 9.54 18.19 9.29 18.14 9.22C18.04 9.11 16.53 9.16 16.28 9.15C17.02 9.09 17.82 9.2 18.55 9.14L18.57 9.13Z"
          fill="url(#paint_linear_31_4787_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M12.63 30.43C12.65 29.55 12.63 28.66 12.63 27.78L12.64 26.73C12.64 26.56 12.65 26.38 12.64 26.21C12.63 26.11 12.58 26.07 12.5 26.05C12.34 26 12.12 26.02 11.95 26.03L10.78 26.03C10.49 26.03 10.15 26.07 9.87 26C9.68 25.96 9.5 25.86 9.35 25.74C9.13 25.54 9.02 25.27 9 24.98C8.99 24.72 9 24.45 9 24.19L9 22.77L9.01 18.31C9 16.66 9 15.02 9 13.37L9 11.96C9 11.71 8.98 11.45 9 11.2C9.02 10.97 9.11 10.74 9.27 10.57C9.42 10.4 9.67 10.27 9.9 10.25C10.09 10.22 10.29 10.23 10.48 10.23L11.41 10.23L14.66 10.23L25.03 10.23L28.37 10.23C28.94 10.23 29.53 10.26 30.11 10.22L30.13 10.22C30.41 10.28 30.64 10.39 30.8 10.64C30.88 10.77 30.93 10.91 30.95 11.07C30.98 11.3 30.96 11.56 30.96 11.8C30.95 12.29 30.95 12.79 30.95 13.28L30.96 21.65L30.96 24.03C30.96 24.44 30.99 24.87 30.95 25.27C30.93 25.45 30.88 25.56 30.75 25.69C30.62 25.82 30.46 25.91 30.29 25.95C30.02 26 29.72 25.97 29.44 25.97L28.15 25.97L23.18 25.97L22.01 25.97C21.83 25.97 21.63 25.94 21.47 25.97C20.68 25.95 19.89 25.97 19.1 25.98C18.45 25.98 17.79 25.97 17.14 25.99C16.24 26.78 15.4 27.66 14.56 28.52C13.92 29.16 13.28 29.8 12.63 30.43Z"
          fill="#7B2CBF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M11.2 25.64C10.89 25.67 10.03 25.73 9.76 25.6C9.6 25.52 9.46 25.37 9.41 25.2C9.32 24.88 9.37 23.96 9.37 23.59L9.37 20.53L9.37 14.69C9.36 13.61 9.33 12.51 9.36 11.42C9.36 11.29 9.37 11.04 9.45 10.93C9.54 10.79 9.74 10.63 9.91 10.6C10.12 10.56 10.35 10.58 10.57 10.58L11.72 10.58L15.96 10.58L25.14 10.58L28.4 10.58C28.86 10.58 29.33 10.57 29.8 10.58C29.98 10.58 30.18 10.62 30.33 10.73C30.46 10.84 30.56 10.99 30.58 11.16C30.61 11.4 30.61 13.44 30.57 13.58C30.56 13.6 30.53 13.63 30.52 13.65C30.31 13.77 30.12 13.97 29.93 14.13C29.61 14.41 29.29 14.67 28.96 14.94C28.21 15.54 27.45 16.13 26.7 16.73C25.87 17.39 24.99 18.11 24.1 18.68L24.09 21.27C24.09 21.59 24.12 22.17 24.08 22.46C24.07 22.59 24.01 22.71 23.92 22.81C23.82 22.9 23.71 22.92 23.58 22.93C23.36 22.96 23.12 22.94 22.9 22.94L21.68 22.94L17.85 22.94L17.74 22.94C16.2 23.91 14.41 24.64 12.69 25.2C12.19 25.36 11.71 25.52 11.2 25.64Z"
          fill="#9D4EDD"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M12 14.79C12.13 14.78 12.25 14.81 12.36 14.88C12.5 14.98 12.6 15.13 12.63 15.3C12.63 15.32 12.63 15.34 12.63 15.36C12.63 15.39 12.63 15.41 12.63 15.43C12.63 15.45 12.63 15.47 12.63 15.49C12.63 15.51 12.62 15.54 12.62 15.56C12.61 15.58 12.61 15.6 12.6 15.62C12.59 15.64 12.58 15.66 12.57 15.68C12.57 15.7 12.56 15.72 12.55 15.74C12.53 15.75 12.52 15.77 12.51 15.79C12.41 15.93 12.26 16.01 12.09 16.04C11.95 16.03 11.82 16.01 11.69 15.93C11.68 15.92 11.66 15.9 11.64 15.89C11.63 15.88 11.61 15.86 11.6 15.85C11.58 15.83 11.57 15.82 11.55 15.8C11.54 15.78 11.53 15.76 11.52 15.75C11.5 15.73 11.49 15.71 11.48 15.69C11.47 15.67 11.47 15.65 11.46 15.63C11.45 15.61 11.44 15.59 11.44 15.57C11.43 15.55 11.43 15.53 11.42 15.51C11.4 15.35 11.43 15.16 11.53 15.03C11.65 14.87 11.81 14.82 12 14.79Z"
          fill="#C77DFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M11.89 11.89C12.03 11.88 12.14 11.89 12.27 11.95C12.49 12.06 12.63 12.29 12.71 12.52C12.83 12.91 12.83 13.37 12.64 13.75C12.53 13.98 12.36 14.11 12.12 14.19C12.02 14.19 11.93 14.19 11.83 14.16C11.61 14.08 11.46 13.9 11.37 13.7C11.36 13.67 11.35 13.65 11.34 13.63C11.33 13.6 11.32 13.58 11.31 13.56C11.3 13.53 11.3 13.51 11.29 13.49C11.28 13.46 11.28 13.44 11.27 13.41C11.26 13.39 11.26 13.37 11.26 13.34C11.25 13.32 11.25 13.29 11.24 13.27C11.24 13.24 11.24 13.22 11.24 13.19C11.23 13.17 11.23 13.14 11.23 13.12C11.23 13.09 11.23 13.07 11.23 13.04C11.23 13.02 11.23 13 11.23 12.97C11.24 12.94 11.24 12.92 11.24 12.9C11.24 12.87 11.25 12.84 11.25 12.82C11.25 12.8 11.26 12.77 11.26 12.75C11.27 12.72 11.28 12.7 11.28 12.67C11.29 12.65 11.3 12.63 11.3 12.6C11.31 12.58 11.32 12.56 11.33 12.53C11.44 12.25 11.61 12.02 11.89 11.89Z"
          fill="#C77DFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M17.32 16.79C17.32 16.13 17.24 15.14 17.46 14.54C17.71 13.86 18.21 13.34 18.86 13.04C19.48 12.76 20.24 12.75 20.88 12.99C21.54 13.25 22.12 13.79 22.41 14.44C22.53 14.7 22.63 15.01 22.63 15.3C22.7 15.74 22.64 16.32 22.65 16.78C23.02 16.78 23.67 16.69 23.95 16.98C24.02 17.05 24.06 17.14 24.08 17.23C24.14 17.53 24.09 18.33 24.1 18.68L24.09 21.27C24.09 21.59 24.12 22.17 24.08 22.46C24.07 22.59 24.02 22.71 23.92 22.81C23.82 22.9 23.71 22.92 23.58 22.93C23.36 22.95 23.12 22.94 22.9 22.94L21.68 22.94L17.85 22.94L17.74 22.94C17.34 22.93 16.37 23.02 16.09 22.76C15.97 22.64 15.92 22.5 15.91 22.33C15.9 22 15.91 21.65 15.92 21.32L15.92 19.53L15.92 18.09C15.92 17.82 15.91 17.53 15.94 17.26C15.95 17.15 16.01 17.03 16.09 16.94C16.32 16.71 17 16.79 17.32 16.79Z"
          fill="#3C096C"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M19.21 13.77C19.49 13.66 20.01 13.57 20.31 13.69C20.35 13.71 20.35 13.71 20.36 13.75C20.16 13.72 19.96 13.72 19.75 13.74C19.26 13.84 18.87 14.07 18.59 14.49C18.29 14.95 18.33 15.41 18.33 15.93C18.33 16.22 18.32 16.5 18.33 16.78C18.26 16.78 18.16 16.79 18.1 16.75C18.06 16.51 18.07 15.33 18.11 15.07C18.14 14.92 18.19 14.77 18.26 14.64C18.46 14.22 18.77 13.92 19.21 13.77Z"
          fill="#050038"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M19.58 20.77C19.35 20.65 19.16 20.5 19.02 20.28C18.84 19.99 18.78 19.68 18.85 19.35C18.93 19.04 19.1 18.79 19.38 18.63C19.64 18.47 19.95 18.42 20.24 18.5C20.54 18.58 20.79 18.78 20.93 19.05C21.09 19.33 21.14 19.65 21.05 19.96C20.94 20.34 20.7 20.57 20.37 20.76C20.36 20.96 20.36 21.17 20.38 21.37C20.41 21.53 20.45 21.68 20.5 21.83C20.2 21.83 19.85 21.8 19.55 21.85C19.5 21.84 19.46 21.85 19.42 21.81C19.54 21.46 19.57 21.14 19.58 20.77Z"
          fill="#E0AAFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M19.75 13.74C19.96 13.72 20.16 13.72 20.36 13.75C20.87 13.93 21.28 14.17 21.52 14.67C21.55 14.74 21.58 14.8 21.6 14.87C21.62 14.94 21.63 15 21.65 15.08C21.7 15.43 21.69 16.41 21.65 16.78C20.55 16.79 19.44 16.79 18.34 16.78C18.32 16.5 18.33 16.22 18.33 15.93C18.33 15.41 18.29 14.95 18.59 14.49C18.87 14.07 19.26 13.84 19.75 13.74Z"
          fill="#9D4EDD"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
