import { SVGProps } from 'react';

function SearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24.000000"
      height="24.000000"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="clip19_6588">
          <rect
            id="State=Search"
            width="24.000000"
            height="23.999998"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip19_6588)">
        <path
          id="path (Border)"
          d="M7.8 18.52C8.43 18.66 9.08 18.73 9.75 18.73C10.96 18.73 12.13 18.49 13.24 18.02C13.97 17.71 14.64 17.32 15.26 16.84L21.3 22.89C21.75 23.33 22.45 23.34 22.89 22.89C23.34 22.45 23.34 21.75 22.89 21.3L16.85 15.25C16.99 15.07 17.12 14.88 17.25 14.69C17.55 14.23 17.81 13.75 18.02 13.24C18.24 12.74 18.4 12.22 18.52 11.7C18.66 11.06 18.73 10.41 18.73 9.75C18.73 8.53 18.49 7.36 18.02 6.25C17.81 5.74 17.55 5.26 17.25 4.81C16.92 4.3 16.54 3.83 16.1 3.4C15.66 2.96 15.19 2.58 14.69 2.24C14.23 1.94 13.75 1.69 13.24 1.47C12.74 1.26 12.22 1.09 11.7 0.98C11.06 0.83 10.41 0.76 9.75 0.76C8.53 0.76 7.36 1 6.25 1.47C5.74 1.69 5.26 1.94 4.81 2.24C4.3 2.58 3.83 2.96 3.4 3.4C2.96 3.83 2.57 4.3 2.24 4.81C1.94 5.26 1.69 5.74 1.47 6.25C1.26 6.75 1.09 7.27 0.98 7.8C0.84 8.43 0.76 9.08 0.76 9.75C0.76 10.96 1 12.13 1.47 13.24C1.69 13.75 1.94 14.23 2.24 14.69C2.57 15.19 2.96 15.66 3.4 16.1C3.83 16.53 4.3 16.92 4.81 17.25C5.26 17.55 5.74 17.81 6.25 18.02C6.75 18.24 7.27 18.4 7.8 18.52ZM14.52 14.5C15.14 13.88 15.62 13.17 15.95 12.37C16.31 11.53 16.48 10.66 16.48 9.75C16.48 8.83 16.31 7.96 15.95 7.13C15.8 6.75 15.61 6.4 15.39 6.06C15.14 5.68 14.84 5.32 14.51 4.98C14.18 4.65 13.82 4.36 13.43 4.1C13.1 3.89 12.74 3.7 12.37 3.54C12 3.39 11.63 3.26 11.25 3.18C10.76 3.07 10.26 3.01 9.75 3.01C9.23 3.01 8.73 3.07 8.24 3.18C7.86 3.26 7.49 3.39 7.13 3.54C6.75 3.7 6.4 3.89 6.06 4.1C5.68 4.36 5.32 4.65 4.98 4.98C4.65 5.32 4.36 5.68 4.1 6.06C3.89 6.4 3.7 6.75 3.54 7.13C3.19 7.96 3.01 8.83 3.01 9.75C3.01 10.26 3.07 10.76 3.18 11.25C3.26 11.63 3.39 12 3.54 12.37C3.88 13.17 4.36 13.89 4.98 14.51C5.61 15.13 6.32 15.61 7.13 15.95C7.96 16.31 8.83 16.48 9.75 16.48C10.66 16.48 11.54 16.31 12.37 15.95C12.74 15.8 13.1 15.61 13.43 15.39C13.82 15.14 14.17 14.85 14.51 14.52C14.51 14.51 14.51 14.51 14.52 14.5C14.52 14.5 14.52 14.5 14.52 14.5Z"
          fill="#FFFFFF"
          fillOpacity="1.000000"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default SearchIcon;
