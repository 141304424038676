import clsx from 'clsx';

import avatarImage from 'assets/images/avatar.png';

import styles from './PanelItem.module.scss';

interface PanelItemProps {
  index: number;
}

export default function PanelItem({ index }: PanelItemProps) {
  return (
    <div
      className={clsx(styles.item, {
        [styles.active]: index === 0,
      })}
    >
      <div className={styles.item__header}>
        <div className={styles.item__avatar}>
          <img src={avatarImage} alt="avatar" />
        </div>
        <h4 className={styles.item__name}>Jensen Ackles</h4>
      </div>
      <div className={styles.item__desc}>
        В рамках использования нашего приложения для чата на базе искусственного
        интеллекта, мы предоставляем пользователям уникальную возможность
        общаться с блогером. Это включает в себя возможность проведения общения
        в режиме реального времени, а также позволяет нашим пользователям
        запросить и получить сгенерированные специально для них фотографии.
        Важно отметить, что в соответствии с нашими условиями использования, все
        полученные фотографии являются исключительной собственностью
        пользователя и не подлежат распространению. Пользователь вправе
        использовать полученные изображения только для личного просмотра и не
        имеет права делать скриншоты или распространять изображения каким-либо
        другим способом. Одним из ключевых принципов нашего сервиса является
        конфиденциальность общения между пользователем и блогером-искусственным
        интеллектом. Поэтому блогер не имеет возможности просматривать или
        читать сообщения пользователя. Весь процесс общения осуществляется
        исключительно между пользователем и ИИ блогером без какого-либо участия
        третьей стороны.
      </div>
    </div>
  );
}
