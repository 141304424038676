import { SVGProps } from 'react';

export default function CheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3292 5.12335C12.5372 5.30495 12.5586 5.62081 12.377 5.82883L7.13893 11.8289C7.04393 11.9376 6.90659 12 6.76226 12C6.61786 12 6.48053 11.9376 6.38558 11.8289L4.29034 9.42887C4.10873 9.2208 4.13015 8.90493 4.33817 8.72333C4.54619 8.54173 4.86205 8.56313 5.04366 8.7712L6.76226 10.7397L11.6237 5.17118C11.8053 4.96315 12.1211 4.94174 12.3292 5.12335Z"
        fill="currentColor"
      />
    </svg>
  );
}
