import UploadsPhotoAiStudy from 'components/UploadsPhotoAiStudy';
import { SignUpTabsType } from 'pages/SignUpBloggerPage/SignUpBloggerPage';
import SignUpFooter from '../SignUpFooter';

import styles from './BloggerPhotoAi.module.scss';

interface BloggerPhotoAiProps {
  setTabs: (value: SignUpTabsType) => void;
}

export default function BloggerPhotoAi({ setTabs }: BloggerPhotoAiProps) {
  const handleTest = () => {
    setTabs('audio');
  };

  return (
    <>
      <h2 className={styles.title}>Customize your AI</h2>
      <div className={styles.content}>
        <UploadsPhotoAiStudy />
      </div>
      <SignUpFooter onClick={handleTest} />
    </>
  );
}
