import Button from 'components/UI/Button';
import AddIcon from 'assets/icons/AddIcon';
import PanelItem from '../PanelItem';

import styles from './ChatsPanel.module.scss';

export default function ChatsPanel() {
  return (
    <div className={styles.panel}>
      <div className={styles.panel__header}>
        <h4 className={styles.panel__title}>Chats</h4>
        <Button variants="filled" className={styles.panel__add}>
          <AddIcon />
        </Button>
      </div>
      <div className={styles.panel__list}>
        {[...Array.from({ length: 10 })].map((_, index) => (
          <PanelItem key={index} index={index} />
        ))}
      </div>
    </div>
  );
}
