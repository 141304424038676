import { SVGProps } from 'react';

export default function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7515 20.499C11.3375 20.499 11.0015 20.163 11.0015 19.749V5.559L6.2825 10.278C5.9885 10.572 5.5145 10.572 5.2205 10.278C4.9265 9.984 4.9265 9.51 5.2205 9.216L11.2205 3.216L11.2235 3.213L11.2265 3.21C11.2265 3.21 11.2265 3.21 11.2295 3.207C11.2295 3.207 11.2295 3.207 11.2325 3.207C11.3015 3.141 11.3825 3.09 11.4665 3.054C11.5445 3.021 11.6315 3.003 11.7215 3H11.7245C11.7395 3 11.7575 3 11.7725 3H11.7755C11.8655 3.003 11.9495 3.021 12.0305 3.054C12.1145 3.09 12.1955 3.141 12.2645 3.207C12.2645 3.207 12.2645 3.207 12.2675 3.21L12.2705 3.213L12.2735 3.216L18.2735 9.216C18.5675 9.51 18.5675 9.984 18.2735 10.278C17.9795 10.572 17.5055 10.572 17.2115 10.278L12.5015 5.559V19.749C12.5015 20.163 12.1655 20.499 11.7515 20.499Z"
        fill="currentColor"
      />
    </svg>
  );
}
