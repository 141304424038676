import { SignUpTabsType } from 'pages/SignUpBloggerPage/SignUpBloggerPage';
import SignUpFooter from '../SignUpFooter';

import styles from './BloggerStudyAi.module.scss';

interface BloggerStudyAiProps {
  setTabs: (value: SignUpTabsType) => void;
}

export default function BloggerStudyAi({ setTabs }: BloggerStudyAiProps) {
  const handleTest = () => {
    setTabs('photo');
  };

  return (
    <>
      <h2 className={styles.title}>Customize your AI</h2>
      <textarea
        className={styles.textarea}
        placeholder="Tell us a little about yourself"
      />
      <SignUpFooter onClick={handleTest} />
    </>
  );
}
