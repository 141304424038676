import { SVGProps } from 'react';

export default function AttachIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3652 4.70585C16.2912 3.63191 14.55 3.63191 13.4761 4.70585L5.3444 12.8376C3.68467 14.4973 3.68467 17.1883 5.3444 18.848C7.00413 20.5077 9.69508 20.5077 11.3548 18.848L20.1168 10.086C20.4096 9.79312 20.8845 9.79312 21.1774 10.086C21.4703 10.3789 21.4703 10.8538 21.1774 11.1467L12.4154 19.9087C10.1699 22.1542 6.52926 22.1542 4.28374 19.9087C2.03823 17.6631 2.03823 14.0224 4.28374 11.7769L12.4154 3.64519C14.0752 1.98546 16.7661 1.98546 18.4258 3.64519C20.0856 5.30492 20.0856 7.99587 18.4258 9.6556L10.6323 17.4492C9.55839 18.5231 7.81719 18.5231 6.74324 17.4492C5.6693 16.3752 5.6693 14.634 6.74324 13.5601L13.9373 6.36601C14.2301 6.07312 14.705 6.07312 14.9979 6.36601C15.2908 6.65891 15.2908 7.13378 14.9979 7.42667L7.8039 14.6207C7.31575 15.1089 7.31575 15.9003 7.8039 16.3885C8.29206 16.8766 9.08352 16.8766 9.57167 16.3885L17.3652 8.59494C18.4391 7.52099 18.4391 5.77979 17.3652 4.70585Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3648 3.64546C17.6577 3.35257 18.1327 3.35255 18.4255 3.64544C20.0853 5.30517 20.0853 7.99612 18.4255 9.65585L10.632 17.4494C9.55811 18.5233 7.81691 18.5233 6.74296 17.4494C5.66901 16.3754 5.66901 14.6342 6.74296 13.5603L13.937 6.36626C14.2298 6.07337 14.7047 6.07337 14.9976 6.36626C15.2905 6.65916 15.2905 7.13403 14.9976 7.42692L7.80361 14.6209C7.31546 15.1091 7.31546 15.9005 7.80361 16.3887C8.29177 16.8768 9.08323 16.8768 9.57138 16.3887L17.3649 8.59519C18.4388 7.52124 18.4388 5.78004 17.3649 4.7061C17.072 4.41321 17.0719 3.93835 17.3648 3.64546Z"
        fill="currentColor"
      />
    </svg>
  );
}
