import {AxiosRequestConfig} from 'axios';
import Api from '../Api';
import {SubscriptionsType} from "../../types/Subscriptions.type";
import {PlanEnum} from "../../types/interfaces/plan.enum";

class SubscriptionsMethods extends Api {
    async getSubscription(userId: number): Promise<SubscriptionsType> {
        const config: AxiosRequestConfig = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };

        return await this.request<SubscriptionsType>(`/subscriptions/${userId}`, config);
    }

    async createSubscription(userId: number, plan: PlanEnum, price:number) {
        const config: AxiosRequestConfig = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                userId,
                plan,
                price
            },
        };

        return await this.request<any>(`/subscriptions`, config);
    }

    async updateSubscription(userId: number, plan: string): Promise<SubscriptionsType> {
        const config: AxiosRequestConfig = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                plan,
            },
        };

        return await this.request<SubscriptionsType>(`/subscriptions/${userId}`, config);
    }

    async deleteSubscription(userId: number): Promise<any> {
        const config: AxiosRequestConfig = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        };

        return await this.request<SubscriptionsType>(`/subscriptions/${userId}`, config);
    }
}

export default new SubscriptionsMethods();
