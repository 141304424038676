import { SVGProps } from 'react';

export default function ReferralIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2701_153)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 2.4375C8.15381 2.4375 7.0625 3.52881 7.0625 4.875C7.0625 6.22119 8.15381 7.3125 9.5 7.3125C10.8462 7.3125 11.9375 6.22119 11.9375 4.875C11.9375 3.52881 10.8462 2.4375 9.5 2.4375ZM5.9375 4.875C5.9375 2.90749 7.53249 1.3125 9.5 1.3125C11.4675 1.3125 13.0625 2.90749 13.0625 4.875C13.0625 6.84251 11.4675 8.4375 9.5 8.4375C7.53249 8.4375 5.9375 6.84251 5.9375 4.875Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4375 3.375C13.4375 3.06434 13.6893 2.8125 14 2.8125C15.453 2.8125 16.8125 3.81214 16.8125 5.25C16.8125 6.68786 15.453 7.6875 14 7.6875C13.6893 7.6875 13.4375 7.43566 13.4375 7.125C13.4375 6.81434 13.6893 6.5625 14 6.5625C15.0324 6.5625 15.6875 5.88321 15.6875 5.25C15.6875 4.61679 15.0324 3.9375 14 3.9375C13.6893 3.9375 13.4375 3.68566 13.4375 3.375Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3.9375C3.96764 3.9375 3.3125 4.6168 3.3125 5.25C3.3125 5.8832 3.96764 6.5625 5 6.5625C5.31066 6.5625 5.5625 6.81434 5.5625 7.125C5.5625 7.43566 5.31066 7.6875 5 7.6875C3.54709 7.6875 2.1875 6.68786 2.1875 5.25C2.1875 3.81214 3.54709 2.8125 5 2.8125C5.31066 2.8125 5.5625 3.06434 5.5625 3.375C5.5625 3.68566 5.31066 3.9375 5 3.9375Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.63004 11.4717C5.91626 11.9476 5.5625 12.5432 5.5625 13.125C5.5625 13.7068 5.91626 14.3024 6.63004 14.7783C7.33988 15.2515 8.35292 15.5625 9.5 15.5625C10.6471 15.5625 11.6601 15.2515 12.37 14.7783C13.0837 14.3024 13.4375 13.7068 13.4375 13.125C13.4375 12.5432 13.0837 11.9476 12.37 11.4717C11.6601 10.9985 10.6471 10.6875 9.5 10.6875C8.35292 10.6875 7.33988 10.9985 6.63004 11.4717ZM6.006 10.5357C6.92484 9.92309 8.1618 9.5625 9.5 9.5625C10.8382 9.5625 12.0752 9.92309 12.994 10.5357C13.9089 11.1456 14.5625 12.05 14.5625 13.125C14.5625 14.2 13.9089 15.1044 12.994 15.7143C12.0752 16.3269 10.8382 16.6875 9.5 16.6875C8.1618 16.6875 6.92484 16.3269 6.006 15.7143C5.0911 15.1044 4.4375 14.2 4.4375 13.125C4.4375 12.05 5.0911 11.1456 6.006 10.5357Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9507 10.7546C15.0172 10.4512 15.3172 10.2591 15.6206 10.3257C16.342 10.4839 16.9921 10.7696 17.4747 11.1645C17.9569 11.5591 18.3126 12.1015 18.3126 12.7501C18.3126 13.3987 17.9569 13.9411 17.4747 14.3357C16.9921 14.7306 16.342 15.0164 15.6206 15.1746C15.3172 15.2411 15.0172 15.0491 14.9507 14.7456C14.8841 14.4422 15.0762 14.1422 15.3796 14.0757C15.9739 13.9454 16.4488 13.7215 16.7623 13.465C17.0761 13.2082 17.1876 12.9574 17.1876 12.7501C17.1876 12.5429 17.0761 12.292 16.7623 12.0352C16.4488 11.7787 15.9739 11.5549 15.3796 11.4246C15.0762 11.358 14.8841 11.0581 14.9507 10.7546Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.04944 10.7546C4.11599 11.0581 3.92394 11.358 3.62049 11.4246C3.02621 11.5549 2.55128 11.7787 2.23784 12.0352C1.92397 12.292 1.8125 12.5429 1.8125 12.7501C1.8125 12.9574 1.92397 13.2082 2.23784 13.465C2.55128 13.7215 3.02621 13.9454 3.62049 14.0757C3.92394 14.1422 4.11599 14.4422 4.04944 14.7456C3.9829 15.0491 3.68296 15.2411 3.37951 15.1746C2.6581 15.0164 2.00803 14.7306 1.5254 14.3357C1.04318 13.9411 0.6875 13.3987 0.6875 12.7501C0.6875 12.1015 1.04318 11.5591 1.5254 11.1646C2.00803 10.7696 2.6581 10.4839 3.37951 10.3257C3.68296 10.2591 3.9829 10.4512 4.04944 10.7546Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2701_153">
          <rect
            width="18"
            height="18"
            fill="currentColor"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
