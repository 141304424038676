import { Link } from 'react-router-dom';

import { useFetchMyProfile } from 'hooks/query/useFetchMyProfile';
import { RoleEnum } from 'types/interfaces/role.enum';
import LeftArrow from 'assets/icons/LeftArrow';
import LogoIcon from 'assets/icons/LogoIcon';

import styles from './Header.module.scss';

export default function Header() {
  const { user } = useFetchMyProfile();

  return (
    <header className={styles.header}>
      <div className={styles.header__logo}>
        <LogoIcon />
        <h3 className={styles.header__title}>Chatly.ai</h3>
      </div>
      {user?.role === RoleEnum.BLOGGER ? (
        <Link to="/settings" className={styles.header__button}>
          <LeftArrow />
          Settings
        </Link>
      ) : user?.role === RoleEnum.USER ? (
        <Link to="/chats" className={styles.header__button}>
          <LeftArrow />
          Chats
        </Link>
      ) : null}
    </header>
  );
}
