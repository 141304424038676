import { SUBSCRIPTIONLIST } from 'constants/setting';

import styles from './EditSubscription.module.scss';

export default function EditSubscription() {
  return (
    <div className={styles.edit}>
      <h2 className={styles.edit__title}>Edit subscription</h2>
      <div className={styles.edit__content}>
        {SUBSCRIPTIONLIST.map((elem) => (
          <div key={elem.id} className={styles.item}>
            <span className={styles.item__title}>{elem.title}</span>
            <label className={styles.item__label}>
              <input
                type="number"
                className={styles.item__input}
                placeholder="0"
              />
              <span className={styles.item__currency}>₽</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
