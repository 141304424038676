import { SVGProps } from 'react';

export default function DeleteIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3751 4.5H2.625"
        stroke="#EC4B5B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.125 6.375L13.78 11.5493C13.6473 13.5405 13.5809 14.5361 12.9321 15.143C12.2834 15.75 11.2856 15.75 9.29 15.75H8.71002C6.71439 15.75 5.71659 15.75 5.06783 15.143C4.41908 14.5361 4.3527 13.5405 4.21995 11.5493L3.875 6.375"
        stroke="#EC4B5B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.125 8.25L7.5 12"
        stroke="#EC4B5B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.875 8.25L10.5 12"
        stroke="#EC4B5B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.875 4.5C4.91691 4.5 4.93786 4.5 4.95686 4.49952C5.57444 4.48387 6.11927 4.09118 6.32942 3.51024C6.33588 3.49237 6.3425 3.47249 6.35576 3.43273L6.42857 3.21428C6.49073 3.02781 6.52181 2.93457 6.56303 2.8554C6.72751 2.53955 7.03181 2.32023 7.38346 2.26408C7.4716 2.25 7.5699 2.25 7.76647 2.25H10.2335C10.4301 2.25 10.5284 2.25 10.6166 2.26408C10.9682 2.32023 11.2725 2.53955 11.437 2.8554C11.4782 2.93457 11.5093 3.02781 11.5715 3.21428L11.6443 3.43273C11.6575 3.47244 11.6641 3.49238 11.6706 3.51024C11.8807 4.09118 12.4255 4.48387 13.0432 4.49952C13.0621 4.5 13.0831 4.5 13.125 4.5"
        stroke="#EC4B5B"
        strokeWidth="1.5"
      />
    </svg>
  );
}
