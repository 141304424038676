import { useState } from 'react';

import Button from 'components/UI/Button';
import UploadsAudioAiStudy from 'components/UploadsAudioAiStudy';
import UploadsPhotoAiStudy from 'components/UploadsPhotoAiStudy';

import styles from './EditAiModel.module.scss';

export default function EditAiModel() {
  const [text, setText] = useState('');

  return (
    <div className={styles.edit}>
      <div className={styles.description}>
        <h3 className={styles.description__title}>Model description</h3>
        <label className={styles.description__label}>
          <textarea
            placeholder="Describe your model's communication style..."
            className={styles.description__textarea}
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
          <Button variants="filled" className={styles.description__button}>
            Save
          </Button>
        </label>
      </div>
      <UploadsPhotoAiStudy />
      <UploadsAudioAiStudy />
    </div>
  );
}
