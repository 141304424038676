import { SVGProps } from 'react';

export default function PlayIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.2115 12.8034C29.0407 14.342 29.0407 18.3247 26.2115 19.8632L9.12883 29.1527C6.37913 30.648 3 28.7017 3 25.6228V7.04387C3 3.96491 6.37913 2.01869 9.12881 3.51397L26.2115 12.8034Z"
        fill="currentColor"
      />
    </svg>
  );
}
