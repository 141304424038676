import clsx from 'clsx';

import Button from 'components/UI/Button';
import SearchIcon from 'assets/icons/SearchIcon';
import avatar from '../../assets/images/avatar.png';

import styles from './SubscriptionsPage.module.scss';

export default function SubscriptionsPage() {
  return (
    <div className={styles.subscriptions}>
      <div className={clsx('container', styles.container)}>
        <label className={styles.label}>
          <input
            className={styles.input}
            type="text"
            placeholder="Enter name..."
          />
          <Button variants="filled" className={styles.search}>
            <SearchIcon />
          </Button>
        </label>
        <div className={styles.cards}>
          {[...Array.from({ length: 15 })].map((_, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.card__info}>
                <div className={styles.card__imageWrapper}>
                  <img src={avatar} alt="avatar" />
                </div>
                <p className={styles.card__name}>Jensen Ackles</p>
              </div>
              <div className={styles.card__actions}>
                <div className={styles.card__price}>800₽ / week</div>
                <Button className={styles.card__button} variants="red">
                  Cancel subscription
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
