import React from 'react';
import clsx from 'clsx';

import Button from 'components/UI/Button';
import SearchIcon from 'assets/icons/SearchIcon';
import avatar from 'assets/images/avatar.png';

import styles from './CatalogPage.module.scss';

function CatalogPage() {
  return (
    <div className={styles.catalog}>
      <div className={clsx('container', styles.content)}>
        <div className={styles.catalog__wrapper}>
          <label className={styles.catalog__inputWrapper}>
            <input
              className={styles.catalog__input}
              type="text"
              placeholder="Search"
            />
            <Button variants="filled" className={styles.catalog__searchBtn}>
              <SearchIcon />
            </Button>
          </label>
          <div className={styles.cards}>
            {[...Array.from({ length: 10 })].map((_, index) => (
              <div className={styles.card} key={index}>
                <div className={styles.card__userInfo}>
                  <div className={styles.card__avatarWrapper}>
                    <img
                      className={styles.card__avatar}
                      src={avatar}
                      alt="avatar"
                    />
                  </div>
                  <p>Jensen Ackles</p>
                </div>
                <div className={styles.card__content}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogPage;
