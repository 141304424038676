import { SVGProps } from 'react';

export default function PauseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.66699 7.99935C2.66699 5.48519 2.66699 4.22811 3.44805 3.44707C4.22909 2.66602 5.48617 2.66602 8.00033 2.66602C10.5145 2.66602 11.7716 2.66602 12.5526 3.44707C13.3337 4.22811 13.3337 5.48519 13.3337 7.99935V23.9993C13.3337 26.5135 13.3337 27.7705 12.5526 28.5516C11.7716 29.3327 10.5145 29.3327 8.00033 29.3327C5.48617 29.3327 4.22909 29.3327 3.44805 28.5516C2.66699 27.7705 2.66699 26.5135 2.66699 23.9993V7.99935Z"
        fill="currentColor"
      />
      <path
        d="M18.667 7.99935C18.667 5.48519 18.667 4.22811 19.4481 3.44707C20.2291 2.66602 21.4862 2.66602 24.0003 2.66602C26.5145 2.66602 27.7715 2.66602 28.5526 3.44707C29.3337 4.22811 29.3337 5.48519 29.3337 7.99935V23.9993C29.3337 26.5135 29.3337 27.7705 28.5526 28.5516C27.7715 29.3327 26.5145 29.3327 24.0003 29.3327C21.4862 29.3327 20.2291 29.3327 19.4481 28.5516C18.667 27.7705 18.667 26.5135 18.667 23.9993V7.99935Z"
        fill="currentColor"
      />
    </svg>
  );
}
